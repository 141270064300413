@import "./replica/mixins";

:root {
  --modal-box-base-color: var(--generic-black-rgb);
  --modal-box-base-opacity: 0.75;
  --modal-box-background: var(--generic-black);
  --modal-box-color: var(--generic-gray);
  --modal-box-success-background: var(--generic-green);
  --modal-box-success-color: var(--generic-white);
  --modal-box-warning-background: var(--generic-red);
  --modal-box-warning-color: var(--generic-white);

  .modal-box-container > div:first-of-type {
    margin-right: 1em;
  }

  .modal-box-container{
    border:1px solid var(--generic-gray);
    width: auto !important;
    @extend %border-radius-medium;

    .modal-box-buttons{
      margin: 0;
      font-size:1.25em;
      white-space: nowrap;

      button{
        padding: 0.75em 2em;
        border: 1px solid var(--generic-white);
        background-color: transparent;
        margin: 0 1em 0;
        color: var(--generic-white);
        @extend %border-radius-medium;

        span{
          font-size: 1.15em;
          font-weight: bold;
        }
      }
    }

    &.warning,
    &.success{
      border:1px solid var(--generic-white);

      button{
        color: var(--generic-white);
        border: 1px solid var(--generic-white);
      }
    }
  }
}
