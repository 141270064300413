:root .modal-box-container .modal-box-buttons button, :root .modal-box-container {
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}

/*
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%appearance-none {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%font-expanded{
  font-size: 0.75em;
  text-transform: uppercase;
  font-stretch: expanded;
  font-weight: bold;
}
*/
:root {
  --modal-box-base-color: var(--generic-black-rgb);
  --modal-box-base-opacity: 0.75;
  --modal-box-background: var(--generic-black);
  --modal-box-color: var(--generic-gray);
  --modal-box-success-background: var(--generic-green);
  --modal-box-success-color: var(--generic-white);
  --modal-box-warning-background: var(--generic-red);
  --modal-box-warning-color: var(--generic-white);
}
:root .modal-box-container > div:first-of-type {
  margin-right: 1em;
}
:root .modal-box-container {
  border: 1px solid var(--generic-gray);
  width: auto !important;
}
:root .modal-box-container .modal-box-buttons {
  margin: 0;
  font-size: 1.25em;
  white-space: nowrap;
}
:root .modal-box-container .modal-box-buttons button {
  padding: 0.75em 2em;
  border: 1px solid var(--generic-white);
  background-color: transparent;
  margin: 0 1em 0;
  color: var(--generic-white);
}
:root .modal-box-container .modal-box-buttons button span {
  font-size: 1.15em;
  font-weight: bold;
}
:root .modal-box-container.warning, :root .modal-box-container.success {
  border: 1px solid var(--generic-white);
}
:root .modal-box-container.warning button, :root .modal-box-container.success button {
  color: var(--generic-white);
  border: 1px solid var(--generic-white);
}