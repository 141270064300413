@import "./replica/mixins";

:root {
  --wireless-fore-color: var(--generic-white);
  --wireless-highlight-color: var(--generic-white);
  --wireless-connect: var(--generic-dark-gray);
  --wireless-disconnect: var(--generic-red);
  --wireless-warning: var(--generic-red);

  .wireless-container{
    button{
      padding: 0.75em 2em;
      border: 1px solid var(--generic-white);
      background-color: transparent;
      width: 30%;
      color: var(--generic-white);
      @extend %border-radius-medium;

      span{
        font-size: 1.15em;
        font-weight: bold;
      }

      &.disconnect{
        color: var(--generic-white);
        background-color: var(--generic-red);
        border: 1px solid var(--generic-red);
      }
    }
  }

  .wireless-form-container{
    margin-bottom: 2em;

    input
    {
      color:var(--generic-light-gray) !important;
      border: 1px solid var(--generic-light-gray) !important;

      @extend %border-radius-smaller;
    }
  }
}
