@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.scrollable::-webkit-scrollbar-track, .scrollable::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
}

span, div, a, h1, h2, h3, h4, h5, h6, p, ul li {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/*
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%appearance-none {
  appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

%font-expanded{
  font-size: 0.75em;
  text-transform: uppercase;
  font-stretch: expanded;
  font-weight: bold;
}
*/
:root {
  /** simulator colors **/
  --generic-transparent: transparent;
  --generic-white: #FFF;
  --generic-black: #000;
  --generic-gray: #6F6F6E;
  --generic-dark-gray: #434445;
  --generic-blue: #2D2E82;
  --generic-trans-blue: #0D0F23;
  --generic-red: #D54C48;
  --generic-green: #317c3b;
  --generic-trans-red: #462628;
  --generic-light-gray: #D9D9D9;
  --generic-trans-gray: #333332;
  --generic-trans-dark: #1D1D1B;
  --generic-background: #131211;
  --generic-black-rgb: 0, 0, 0;
  --keyboard-color-white: var(--generic-white);
  --keyboard-color-medium-gray: var(--generic-gray);
  --keyboard-color-dark-gray: var(--generic-dark-gray);
  --keyboard-color-dark-blue: var(--generic-blue);
  /** cardiohelp colors **/
  --cardiohelp-gray: #706F6E;
  --cardiohelp-light-blue: #E0EDF9;
  --cardiohelp-medium-blue: #1D70B6;
  --cardiohelp-medium-dark-blue: #50658C;
  --cardiohelp-dark-blue: #233360;
  --cardiohelp-light-red: #ff5e5e;
  --cardiohelp-va-ecls: #E5332A;
  --cardiohelp-vv-ecls: #1641E3;
  --cardiohelp-vad: #00A099;
  --cardiohelp-mecc: #29235C;
  --cardiohelp-palp: #006D83;
  --cardiohelp-led-off: #585856;
  --cardiohelp-led-on: #DDDB01;
  --cardiohelp-disabled-gray: #dfdfdf;
  --cardiohelp-green: #2fdc39;
  --cardiohelp-yellow: #efcb29;
  --cardiohelp-red: #be332b;
  --cardiohelp-border: 2px solid var(--cardiohelp-dark-blue);
  /** novalung colors **/
  --novalung-gray: #2e2e30;
  --novalung-background:#585856;
  --novalung-screen:#3C3C3B;
  --novalung-shape: #5F5951;
  --novalung-shape-button:#a89b92;
  --novalung-red: #BD1622;
  --novalung-yellow: #FFF799;
  --novalung-blue: #66C5EE;
  --novalung-green:#28A66C;
  --novalung-text: #ECECEC;
  --novalung-dark-text: #2D2E35;
  --novalung-blue-gray: #95c0c3;
  --novalung-highlighted-blue: #66C5EE;
  --novalung-highlighted-green:#28A66C;
  --novalung-highlighted-red:#E5332A;
  --novalung-highlighted-yellow:#F1F199;
  --novalung-background-screen: #454C55;
  --novalung-light-gray: #838F92;
  --novalung-medium-green: #6C8F92;
  --novalung-grafic-yellow: #F1F199;
  --novalung-grafic-orange: #F19576;
  --novalung-grafic-red: #EA5C55;
  --novalung-light-gray-rgb: 131, 143, 146;
  --novalung-transparent: transparent;
  --novalung-statics-lpm: #be332b;
  --novalung-statics-rpm: #efcb29;
  --novalung-statics-pven: #0099ff;
  --novalung-statics-pint: #00d9ff;
  --novalung-statics-part: #db26d2;
  --novalung-statics-p4: #3c83e0;
  --novalung-statics-tven: #28A66C;
  --novalung-statics-tart: #F1F199;
  --novalung-statics-ap: #f57600;
  --novalung-statics-power: #ddaedd;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-tap-highlight-color: transparent; /* i.e. Nexus5/Chrome and Kindle Fire HD 7'' */
}

html {
  --ion-font-family: "Arial";
  --ion-text-color: var(--generic-black);
  --ion-background-color: var(--generic-white);
  font-size: 16px;
}

body {
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-overflow-scrolling: touch;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.25;
}

@media only screen and (max-height: 2560px) {
  h1, h2, h3, h4, h5, h6, p {
    line-height: 1.25;
  }
}
@media only screen and (max-height: 1280px) {
  h1, h2, h3, h4, h5, h6, p {
    line-height: 0.75;
  }
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  line-height: 1.25;
}

input {
  outline: none;
}

span {
  line-height: 1.25;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.v-hidden {
  visibility: hidden !important;
}

.scrollable {
  overflow: hidden;
  overflow-y: auto;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: var(--generic-gray) transparent;
  scrollbar-width: thin;
  /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
}
.scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scrollable::-webkit-scrollbar:vertical {
  width: 0.2em;
}
.scrollable::-webkit-scrollbar-button:increment, .scrollable::-webkit-scrollbar-button {
  display: none;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--generic-gray);
}
.scrollable::-webkit-scrollbar-track {
  background: transparent;
}